import React from "react"
import SEO from "components/seo"

const DataRetentionPolicyPage = () => (
  <>
    <SEO
      title="Sourcery | Data Retention Policy"
      description="Learn about Sourcery's Data Retention Policy, including how we manage and securely delete your personal data."
    />

    <div className="bg-[#111111]">
      <div className="mx-auto px-6 pt-6 max-w-2xl">
        <h1 className="text-2xl text-left text-white 2xl:text-5xl md:text-4xl mt-28 lg:mt-40 lg:text-left">
          Data Retention Policy
        </h1>
        <p className="text-base text-left text-white lg:text-left opacity-40 mt-5">
          Effective as of November 15, 2024
        </p>
      </div>

      <div className="px-6 py-6 mx-auto max-w-2xl text-white">
        <div>
          <h3 className="text-2xl py-6 mt-3">Introduction</h3>
          <p>
            At Sourcery.AI Limited (&quot;Sourcery&quot;), we are committed to
            safeguarding your personal data and ensuring transparency in our
            data handling practices. This Data Retention Policy outlines the
            principles we follow to manage data responsibly, the types of data
            we collect, the duration for which we retain it, and the procedures
            for its secure deletion.
          </p>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">Scope</h3>
          <p>
            This policy applies to all data collected, processed, and stored by
            Sourcery, including personal data of customers, end-users,
            employees, and any other individuals whose data is collected through
            Sourcery’s services.
          </p>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">Retention Principles</h3>
          <p>We adhere to the following principles when managing data:</p>
          <ul className="list-inside list-disc gap-3 mt-3">
            <li>
              <b>Minimization:</b> We only collect and retain personal data
              necessary to provide our services effectively.
            </li>
            <li>
              <b>Transparency:</b> Data retention periods are clearly defined
              and communicated where applicable.
            </li>
            <li>
              <b>Security:</b> Data is stored securely using encryption and
              other safeguards.
            </li>
            <li>
              <b>Right to Erasure:</b> Individuals may request the deletion of
              their data, subject to certain legal or contractual obligations.
            </li>
          </ul>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">Retention Periods</h3>
          <p>
            The following table summarizes the retention periods for different
            types of data collected and processed by Sourcery:
          </p>
          <table className="mt-5 border-collapse border border-white text-white w-full">
            <thead>
              <tr className="bg-gray-700 text-left">
                <th className="border border-white px-4 py-2">Data Type</th>
                <th className="border border-white px-4 py-2">Purpose</th>
                <th className="border border-white px-4 py-2">
                  Retention Period
                </th>
                <th className="border border-white px-4 py-2">
                  Deletion Method
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-white px-4 py-2">
                  Customer Information
                </td>
                <td className="border border-white px-4 py-2">
                  Name, email, and GitHub/GitLab username for account management
                  and authentication.
                </td>
                <td className="border border-white px-4 py-2">
                  Retained while the account is active.
                </td>
                <td className="border border-white px-4 py-2">
                  Deleted upon account termination or request.
                </td>
              </tr>
              <tr>
                <td className="border border-white px-4 py-2">Source Code</td>
                <td className="border border-white px-4 py-2">
                  Processed for code review and coding assistant services.
                </td>
                <td className="border border-white px-4 py-2">
                  Deleted immediately after processing.
                </td>
                <td className="border border-white px-4 py-2">
                  N/A (not stored).
                </td>
              </tr>
              <tr>
                <td className="border border-white px-4 py-2">
                  Payment Information
                </td>
                <td className="border border-white px-4 py-2">
                  Handled by Stripe for subscription payments.
                </td>
                <td className="border border-white px-4 py-2">
                  As per Stripe&apos;s regulatory compliance policies.
                </td>
                <td className="border border-white px-4 py-2">
                  Managed by Stripe.
                </td>
              </tr>
              <tr>
                <td className="border border-white px-4 py-2">
                  Product Usage Data
                </td>
                <td className="border border-white px-4 py-2">
                  Feature usage analytics to improve services.
                </td>
                <td className="border border-white px-4 py-2">
                  Retained while the account is active.
                </td>
                <td className="border border-white px-4 py-2">
                  Deleted upon account termination or request.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">Third-Party Services</h3>
          <p>
            Sourcery relies on third-party service providers for certain
            operations. Each provider has its own retention and deletion
            policies:
          </p>
          <ul className="list-inside list-disc gap-3 mt-3">
            <li>
              <b>Auth0:</b> User authentication and account management.
            </li>
            <li>
              <b>Langsmith:</b> Processes LLM message data and analytics.
            </li>
            <li>
              <b>Sentry:</b> Error handling and reporting.
            </li>
            <li>
              <b>Stripe:</b> Payment processing.
            </li>
            <li>
              <b>Mixpanel:</b> Product usage analytics.
            </li>
            <li>
              <b>Google Analytics:</b> Website analytics.
            </li>
          </ul>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">User Rights</h3>
          <p>Users can exercise the following rights regarding their data:</p>
          <ul className="list-inside list-disc gap-3 mt-3">
            <li>
              <b>Access:</b> Request details of data held by Sourcery.
            </li>
            <li>
              <b>Rectification:</b> Correct inaccuracies in stored data.
            </li>
            <li>
              <b>Erasure:</b> Request deletion of personal data.
            </li>
            <li>
              <b>Restriction:</b> Request limited processing of data.
            </li>
          </ul>
          <p className="mt-5">
            Requests can be submitted to:{" "}
            <a href="mailto:privacy@sourcery.ai" className="underline">
              privacy@sourcery.ai
            </a>
            . Sourcery will respond within 30 days, subject to verification of
            identity.
          </p>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">Review and Updates</h3>
          <p>
            This policy will be reviewed annually or when significant changes
            occur in our services, legal requirements, or operations.
          </p>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">Contact Information</h3>
          <p>
            For any questions or concerns regarding this Data Retention Policy,
            please contact us at:
          </p>
          <p className="mt-5">
            <b>Sourcery.AI Limited</b> <br />
            124 City Road <br />
            London, England, EC1V 2NX <br />
            Email:{" "}
            <a href="mailto:privacy@sourcery.ai" className="underline">
              privacy@sourcery.ai
            </a>
          </p>
        </div>
      </div>
    </div>
  </>
)

export default DataRetentionPolicyPage
